<template>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
            <v-breadcrumbs :items="breadcumbs">
                <template v-slot:item="{ item }">
                    <v-breadcrumbs-item
                        :to="item.href"
                        class="text-subtitle-2 crumb-item"
                        :disabled="item.disabled"
                        exact
                    >
                        {{ item.text }}
                    </v-breadcrumbs-item>
                </template>
            </v-breadcrumbs>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
            <v-card>
                <v-progress-linear
                    :active="loading"
                    :indeterminate="loading"
                    absolute
                    bottom
                    color="deep-purple accent-4"
                ></v-progress-linear>
                <v-container fluid>
                    <v-row>
                        <v-col cols="12" xs="12" sm="5" md="2">
                            <v-autocomplete
                                solo
                                class="ma-0 pa-0"
                                hide-details=true
                                clearable
                                v-model="office"
                                :items="offices"
                                item-value="office_id"
                                item-text="office"
                                label="Office ID"
                                @change="(event) => updateDepartment(event)"
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" xs="12" sm="5" md="2">
                            <v-autocomplete
                                solo
                                class="ma-0 pa-0"
                                hide-details=true
                                clearable
                                v-model="status"
                                :items="statuss"
                                item-value="value"
                                item-text="label"
                                label="Status"
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" xs="12" sm="5" md="2">
                            <v-menu
                                v-model="menuStart"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    solo
                                    v-model="startDate"
                                    label="Tr Start Date"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    class="ma-0 pa-0"
                                    hide-details=true
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                v-model="startDate"
                                @input="menuStart = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>   
                        <v-col cols="12" xs="12" sm="5" md="2">
                            <v-menu
                                v-model="menuEnd"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    solo
                                    v-model="endDate"
                                    label="Tr End Date"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    class="ma-0 pa-0"
                                    hide-details=true
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                v-model="endDate"
                                @input="menuEnd = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>   
                        <v-col cols="12" xs="12" sm="5" md="2" >
                            <v-autocomplete
                                solo
                                class="ma-0 pa-0"
                                hide-details=true
                                clearable
                                v-model="department"
                                :items="departments"
                                item-value="dept_id"
                                item-text="dept_name"
                                label="Send to Dept"
                            ></v-autocomplete>
                        </v-col>  
                        <v-col cols="12" xs="12" sm="5" md="2">
                            <v-autocomplete
                                solo
                                class="ma-0 pa-0"
                                hide-details=true
                                clearable
                                v-model="category"
                                :items="categories"
                                item-value="str1"
                                item-text="str1"
                                label="Work Category"
                            ></v-autocomplete>
                        </v-col>                     
                        <v-col cols="12" xs="12" sm="5" md="2" >
                            <v-autocomplete
                                solo
                                class="ma-0 pa-0"
                                hide-details=true
                                clearable
                                v-model="tenant"
                                :items="tenants"
                                item-value="tenant_id"
                                item-text="tenant_name"
                                label="Tenant ID"
                            ></v-autocomplete>
                        </v-col> 
                        <v-col cols="12" xs="12" sm="5" md="2" >
                            <v-autocomplete
                                solo
                                class="ma-0 pa-0"
                                hide-details=true
                                clearable
                                v-model="priority"
                                :items="priorities"
                                item-value="value"
                                item-text="label"
                                label="Priority"
                            ></v-autocomplete>
                        </v-col> 
                        <v-col cols="12" xs="12" sm="5" md="2" >
                            <v-btn
                                class="mr-2"
                                color="info"
                                elevation="2"
                                large
                                rounded
                                @click="submit()"
                            >
                                <v-icon dark>
                                    mdi-magnify
                                </v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                        <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                        </v-layout>
                        <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                        </v-btn>
                    </v-snackbar>
                </v-container>
            </v-card>
        </v-col>
      </v-row>
      <v-row class="mt-1">
        <v-col cols="12" style="padding-bottom: 100px">
            <v-skeleton-loader v-if="loading_loader" :loading="loading_loader" type="card, table"></v-skeleton-loader>
            <v-card>
                <v-container fluid>
                    <v-row>
                        <v-col cols="12">
                            <v-card-title>Result
                                <v-spacer></v-spacer>
                                <v-text-field
                                    v-model="$store.state.text"
                                    append-icon="mdi-magnify"
                                    label="Search..."
                                    single-line
                                    @keyup.enter="search"
                                    hide-details
                                ></v-text-field>
                            </v-card-title>
                            <v-data-table
                                :headers="headers"
                                :items="orders"
                                :options.sync="options"
                                :loading="loading"
                                loading-text="Please wait, retrieving data"
                                :server-items-length="totalItems"
                                :page.sync="pagination"
                                page-count="10"
                                class="elevation-1"
                            >
                                <template v-slot:[`header.dt_trans`]="{ header }">
                                    <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                    <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                    <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                </template>
                                <template v-slot:[`header.ticket_id`]="{ header }">
                                    <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                    <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                    <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                </template>
                                <template v-slot:[`header.inv_id`]="{ header }">
                                    <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                    <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                    <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                </template>
                                <template v-slot:[`item.stat`]="{ item }">
                                    <v-chip
                                        v-if="item.stat === 'X'"
                                        class="ma-2"
                                        color="red"
                                        text-color="white"
                                    >
                                        Rejected
                                    </v-chip>
                                    <v-chip
                                        v-if="item.stat === 'M'"
                                        class="ma-2"
                                        color="green"
                                        text-color="white"
                                    >
                                        Confirmed
                                    </v-chip>
                                    <v-chip
                                        v-if="item.stat === 'I'"
                                        class="ma-2"
                                        color="deep-purple"
                                        text-color="white"
                                    >
                                        Invoiced
                                    </v-chip>
                                    <v-chip
                                        v-if="item.stat === 'C'"
                                        class="ma-2"
                                    >
                                        Closed
                                    </v-chip>
                                    <v-chip
                                        v-if="item.stat === 'O'"
                                        class="ma-2"
                                        color="yellow"
                                    >
                                        Open
                                    </v-chip>
                                    <v-chip
                                        v-if="item.stat === 'N'"
                                        class="ma-2"
                                        color="primary"
                                        text-color="white"
                                    >
                                        Not Close
                                    </v-chip>
                                </template>
                                <template v-slot:[`item.actions`]="{ item }">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-icon small class="mr-2" @click="showItem(item)" v-on="on">
                                                mdi-eye
                                            </v-icon>
                                        </template>
                                        <span>Show</span>
                                    </v-tooltip>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
            <v-dialog v-model="dialog" persistent max-width="1000px">                
                <v-card>
                    <v-card-title>Detail Work Order</v-card-title>
                    <v-card-text>
                            <v-container fluid>
                            <v-row>
                                <v-col cols="12">
                                    <v-card-title>Work Order Detail
                                        <v-spacer></v-spacer>
                                        <v-text-field
                                            v-model="search_text"
                                            append-icon="mdi-magnify"
                                            label="Search article"
                                            single-line
                                            @keyup.enter="search_detail"
                                            hide-details
                                        ></v-text-field>
                                    </v-card-title>
                                    <v-data-table
                                        :headers="header_details"
                                        :items="order_details"
                                        :options.sync="option_temp_details"
                                        :loading="loading2"
                                        loading-text="Please wait, retrieving data"
                                        :server-items-length="totalItem_details"
                                        :page.sync="pagination_item"
                                        page-count="10"
                                        class="elevation-1"
                                    >
                                        <template v-slot:[`item.qty`]="{ item }">
                                            {{ $store.getters.convertToCurrency(item.qty) }}
                                        </template>
                                        <template v-slot:[`item.unit_price`]="{ item }">
                                            {{ $store.getters.convertToCurrency(item.unit_price) }}
                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
export default {
    data() {
        return {
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                text: 'SR MALL',
                disabled: false,
                href: '/admin/srmall',
                },
                {
                text: 'Information',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'Work Order List',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            loading: false,
            loading_loader: false,
            offices: [],
            office: '',
            statuss: [
                {
                    value: 'O',
                    label: 'O - Open'
                },
                {
                    value: 'N',
                    label: 'N - Not Closed'
                },
                {
                    value: 'M',
                    label: 'M - Confirm'
                },
                {
                    value: 'I',
                    label: 'I - Invoiced'
                },
                {
                    value: 'C',
                    label: 'C - Closed'
                },
                {
                    value: 'X',
                    label: 'X - Rejected'
                },
            ],
            status: '',
            startDate: '',
            endDate: '',
            dateNow: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            menuStart: false,
            menuEnd: false,
            departments: [],
            department: '',
            categories: [],
            category: '',
            tenants: [],
            tenant: '',
            priorities: [
                {
                    value: 'TOP URGENT',
                    label: 'Top Urgent'
                },
                {
                    value: 'ROUTINE',
                    label: 'Routine'
                },
                {
                    value: 'PREVENTIVE',
                    label: 'Preventive'
                },
                {
                    value: 'NEW WORK',
                    label: 'New Work'
                }
            ],
            priority: '',
            display: 'none',
            orders: [],
            data_var: {
                entity_id : 'SRMALL',
                appl_id : 'APPROVAL'
            },
            options: {},
            option_temp_details:{},
            totalItems: 10,
            totalItem_details: 10,
            headers: [
                {
                    text: 'TR Date',
                    align: 'start',
                    sortable: false,
                    value: 'dt_trans',
                },
                { text: 'Ticket ID', value: 'ticket_id', sortable: false },
                { text: 'Inv ID', value: 'inv_id', sortable: false },
                { text: 'Tenant', value: 'tenant_name', sortable: false },
                { text: 'Descr', value: 'descr', sortable: false },
                { text: 'Priority', value: 'priority', sortable: false },
                { text: 'Req By', value: 'request_by', sortable: false },
                { text: 'Phone', value: 'phone', sortable: false },
                { text: 'Department', value: 'dept_name', sortable: false },
                { text: 'Status', value: 'stat', sortable: false },
                { text: 'Actions',  value: 'actions', sortable: false },
            ],
            header_details:[
                {
                    text: 'Ticket ID',
                    align: 'start',
                    sortable: true,
                    value: 'ticket_id',
                },
                { text: 'Article', value: 'description' },
                { text: 'Qty', value: 'qty' },
                { text: 'Unit Price', value: 'unit_price' },
                // { text: 'Actions',  value: 'actions', sortable: false },
            ],
            order_details: [],
            dialog: false,
            data_temp: {},
            search_text: '',
            loading2: false,
            pagination: 1,
            pagination_item: 1,
            sortBy: 'desc',
            sortName: ''
        }
    },
    mounted(){
        this.getOffice()
        this.getEnvConf()
        this.getTenant()
        this.getWorkCategory()
    },
    methods:{
        sortByFunc(sortBy){
            console.log(this.sortBy, sortBy);
            this.sortBy = this.sortBy === 'desc' ? 'asc' : 'desc';
            this.sortName = sortBy
            this.submit(1, 10)
        },
        async submit(page = 1, itemsPerPage = 10){
            this.loading = true
            this.loading_loader = true
            
            await axios.get(`${process.env.VUE_APP_URL}/api/sr_mall/work_order?search=${this.$store.state.text}&txtOffice=${this.office ? this.office : ''}&txtDepartment=${this.department ? this.department : ''}&txtStatus=${this.status ? this.status : ''}&start_date=${this.startDate ? this.startDate : ''}&end_date=${this.endDate ? this.endDate : ''}&wcat_id=${this.category ? this.category : ''}&tenant_id=${this.tenant ? this.tenant : ''}&priority=${this.priority ? this.priority : ''}&sort=${this.sortBy ? this.sortBy : 'desc'}&sortName=${this.sortName ? this.sortName : ''}&page=${page > 1 ? page : ''}&pagination=${itemsPerPage}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.loading = false
                this.loading_loader = false
                this.display = 'block'
                this.orders = res.data.data
                this.totalItems = res.data.total
                this.pagination = res.data.current_page
            })
        },
        showItem(item){
            this.data_temp = item
            this.dialog = true
            this.order_details = []
            this.getDetailOrder(1, 10, item)
        },
        async getDetailOrder(page = 1, itemsPerPage = 10, item){
            this.loading2 = true
            await axios.get(`${process.env.VUE_APP_URL}/api/sr_mall/work_order/show?search=${this.search_text ? this.search_text : ''}&office_id=${item.office_id}&ticket_id=${item.ticket_id}&page=${page > 1 ? page : ''}&pagination=${itemsPerPage}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.loading2 = false
                this.order_details = res.data.data
                this.totalItem_details = res.data.total
                this.pagination_item = res.data.current_page
            })
        },
        close(){
            this.dialog = false
        },
        search(){
            this.orders = []
            this.pagination = 1
            this.submit(1, 10)
        },
        search_detail(){
            this.order_details = []
            this.pagination_item = 1
            this.getDetailOrder(1, 10, this.data_temp)
        },
        getEnvConf(){
            // let data = this.data_var;

            // this.$store.dispatch("getVariabelUser", { data }).then(res => {

            //     for (let i = 0; i < res.data.data.length; i++) {

            //         if (res.data.data[i]['var_id'] === 'APRVPO1') {
            //             this.user_approve1 = res.data.data[i]['var_value']
            //         }
                    
            //         if (res.data.data[i]['var_id'] === 'APRVPO2') {
            //             this.user_approve2 = res.data.data[i]['var_value']
            //         }

            //     }

            // })
        },
        async getOffice(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sr_mall/office`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.offices = res.data
                this.office = res.data[0].office_id
            });
        },
        async updateDepartment(value){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sr_mall/department?office_id=${value}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.departments = res.data
            });
        },
        async getTenant(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sr_mall/tenant`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.tenants = res.data
            });
        },
        async getWorkCategory(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sr_mall/wcat`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.categories = res.data
            });
        },
    },
    watch: {
        options: {
            handler () {
                const { page, itemsPerPage } = this.options
                if (this.office != '' || this.department != '' || this.status != '' || this.category != '' || this.tenant != '' || this.priority != '') {
                    this.submit(page, itemsPerPage)
                }
            },
            deep: true,
        },
    }
}
</script>